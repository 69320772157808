.chat{
    height: 100%;
    display: flex;
    flex-direction: column;
  
    .messages{
        flex:1;
     display: flex;
     flex-direction: column;
     gap:20px;
     overflow-y: scroll;
     h1{
        font-weight: 300;
     }
        .message{
         background-color: white;
         padding: 20px;
         border-radius: 10px;
         display: flex;
         align-items: center;
         gap:20px;
         cursor: pointer;
            img{
                width:40px;
                height: 40px;
                border-radius: 50%;
                object-fit: cover;
            }
            span{
                font-weight: bold;
            }
        }
    }
    .chatbox{
        flex:1;
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top{
            background-color: #f7c14b85;
            padding: 20px;
            font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .user {
            display: flex;
            align-items: center;
            gap:20px;
        
        img{
            width:30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
        }
       
    
    }
    .close{
        cursor: pointer;
    }
        }

        .center{
            height: 350px;
            overflow: scroll;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .chatMessage{
                width:50%;
                &.own{
                    align-self: flex-end;
                    text-align: right;
                }
                span{
                    font-size: 12px;
                    background-color: #f7c14b39;
                    padding: 2px;
                    border-radius: 5px;}
            }
        }
        .bottom{
            border-top: 2px solid #f7c14b85;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            textarea{
                flex:3;
                height: 100%;
                border: none;
                padding: 20px;
            }
            button{
                flex:1;
                background-color: #f7c14b85;
                height: 100%;
                border: none;
                cursor: pointer;
            }
        }

    }
}
@import "../../responsive.scss";
nav {
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a{
    transition: all 0.4s ease;
    @include sm{
        display:none;
    }
    &:hover{
        scale:1.05;
    }
  }
  .left {
    flex: 3;
    display: flex;
    align-items: center;
    gap:50px;
    .logo{
        font-weight: bold;
        font-size: 20px;
        display: flex;
        gap:10px;
        align-items: center;
        img{
            width:28px;
        }
        span{
        @include md{
            display:none;
        }
        @include sm{
            display:initial;
        }
    }
    }
    
  }
  .right {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #fcf5f3;
    height: 100%;
    @include md{
        background-color: transparent;
    }
    a{
        padding: 12px 24px;
        margin: 20px;
    }
    .user{
        display: flex;
        align-items: center;
        font-weight: bold;
        img{
            width:40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 20px;
        }
        span{
            @include sm{
                display: none;
            }
        }
        .profile{
            padding:12px 24px;
            background-color: #fece51;
            cursor: pointer;
            border: none;
            position: relative;
            .notification{
                position: absolute;
                top:-8px;
                right:-8px;
                background-color: red;
                color:white;
                border-radius: 50%;
                width: 26px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .register{
        background-color: #fece51;
    }
    .menuIcon{
        display: none;
        z-index: 999;
        img{
            width:36px;
            height: 36px;
            cursor: pointer;
        }
        @include sm{
            display:inline;
        }
    }
    .menu{
        position: absolute;
        background-color: black;
        top:0;
        right:-50%;
        color:white;
        height: 100vh;
        width:50%;
        transition: all 1s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &.active{
            right:0;
        }
        @include sm{
            a{
            display: initial;
        }
    }
  }

}
}
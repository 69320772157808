.filter{
    display: flex;
    flex-direction: column;
    gap: 10px;
    h1{
        font-weight: 300;
        font-size: 24px;
    }
    .item{
        display: flex;
        flex-direction: column;
        gap:2px;
    }
    label{
        font-size: 10px;
       
       
    }
    input,select{
       
      
        width:100px;
        padding:10px;
        border:1px solid #e0e0e0;
        font-size: 14px;
    }
    .top{
        input{
            width:100%;
        }
    }
    .bottom{
        display:flex;
        justify-content: space-between;
        gap:20px;
       
       
    }
    button{
        background-color: #fece51;
       cursor: pointer;
        width:100px;
        border: none;
        padding:10px;
        img{
            width:24px;
            height: 24px;
        }
    }
    
}
@import "../../responsive.scss";
.slider{
    width:100%;
    height: 350px;
    display: flex;
    gap:20px;
    .fullslider{
        position: absolute;
        width: 100vw;
        height: 100vh;
        top:0;
        left:0;
        background-color: black;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 99;
        .arrow{
            flex:1;
            display: flex;
            align-items: center;
            justify-content: center
            ;
            img{
                width:50px;
                @include md{
                    width:30px;
                }
                @include sm{
                    width:20px;
                }
            }
        }
        .imgcontainer{
            flex:10;
            img{
                width:100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .close{
            position: absolute;
            top:0;
            right: 0;
            color:white;
            font-size: 36px;
            font-weight: bold;
            cursor: pointer;
            padding: 30px;
        }
    }
    img{
        width:100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
        cursor: pointer;
    }
    .bigimage{
        flex:3;
        
    }
    .smallimages{
        flex:1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap:20px;
        img{
            height: 100px;
        }
       
    }
}
.popupcontainer{
    display:flex;
    gap:20px;
    img{
        width:64px;
        height: 48px;
        object-fit: cover;
        border-radius: 5px;
    }
    .textcontainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}
@import "../../responsive.scss";
.singlepage{
    display: flex;
    height: 100%;
    @include md{
        flex-direction: column;
        overflow: scroll;
    }
    .details{
        flex:3;
        height: 100%;
        overflow: auto;
        @include md{
            flex:none;
            height: max-content;
            margin-bottom: 50px;
        }
        .wrapper{
padding-right:50px;
.info{
    margin-top: 50px;
    .top{
        display:flex;
        justify-content: space-between;
        .post{
            display: flex;
            flex-direction: column;
            gap:20px;
            h1{
font-weight: 400;
            }
            .address{
                display:flex;
                gap:5px;
                align-items: center;
                color:#888;
                font-size: 14px;
                img{
                    width:16px;
                    height: 16px;
                }
            }
            .price{
padding:5px;
background-color:rgba(254,205,81,0.438);
border-radius: 5px;
width: max-content;
font-size: 20px;
font-weight: 300;
            }
        }
        .user{
            display: flex;
            flex-direction: column;
            padding:0px 50px;
            background-color:rgba(254, 205, 81, 0.215); 
            justify-content: center;
            align-items: center;
            gap:20px;
            border-radius: 10px;
            font-weight: 600;

            img{
                width:50px;
                height: 50px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }
    .bottom{
        margin-top: 50px;
        color:#555;
        line-height: 20px;
    }
}
        }
    }
    .features{
        flex:2;
        background-color: #fcf5f3;
        height: 100%;
        overflow: auto;
        @include md{
            flex:none;
            height: max-content;
        }
        .wrapper{
            padding:0px 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            img{
                width:24px;
                height: 24px;
            }
            .title{
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 10px;
            }
            .feature{
                display: flex;
                align-items: center;
                gap: 10px;
                img{
                    background-color: #fff4d9
                }
                .featureText{
                    span{
                        font-weight: bold;
                    }
                    p{
                        font-size: 14px;
                    }
                }
            }
            .ListVertical{
                display:flex;
                    flex-direction: column;
                    gap:20px;
                    padding:20px 10px;
                    background-color: white;
                    border-radius: 10px;
            }
            .ListHorizontal{
                display: flex;
                justify-content: space-between;
                gap:20px;
                padding:20px 10px;
                background-color: white;
                border-radius: 10px;
            }
            .sizes{
                display: flex;
                align-items: center;
                justify-content: center;
               gap:10px;
               @include lg{
font-size: 12px;
               }
            }
            .mapContainer{
                width: 100%;
                height: 200px;
            }
            .buttons{
                display: flex;
                justify-content: space-between;
                button{
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    background-color: #fcf5f3;
                    border:1px solid #fece51;
                    border-radius: 5px;
                    cursor: pointer;
                }
            }
                    }
                    
    }
}
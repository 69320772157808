@mixin sm{
    @media (max-width:738px){
        @content;
    }
}
@mixin md{
    @media (max-width:1024px){
        @content;
    }
}
@mixin lg{
    @media (max-width:1366px){
        @content;
    }
}

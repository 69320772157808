@import "../../responsive.scss";
.searchbar{
    
    .type{
        @include sm{
            // margin-bottom: 5px;
        }
        button{
            padding:16px 36px;
            border:1px solid gray;
            cursor:pointer;
            background-color: white;
            text-transform: capitalize;
            border-bottom: none;
            &.active{
                background-color: black;
                color: white;
            }
            &:first-child{
                border-top-left-radius: 5px;
               border-right: none;
               @include sm{
                // border-bottom: 1px solid rgb(12, 12, 12);
               }
            }
            &:last-child{
                border-top-right-radius: 5px;
                border-left: none;
                @include sm{
                    border-bottom: 1px solid rgb(12, 12, 12);
                   }
            }
        }
    }
    form{
         border: 1px solid grey;
         display: flex;
         justify-content: space-between;
         height: 64px;
         gap:5px;
         @include sm{
            flex-direction: column;
            border: none;
           
         
        }
         input{
            border: none;
            padding:0px 10px;
            width:200px;

           @include lg{
            padding:0px 5px;
            &:nth-child(2),&:nth-child(3){
                width:150px;
            }
           
           }
           @include md{
            width:200px;
            &:nth-child(2),&:nth-child(3){
                width:200px;
            }
           
           }
           @include sm{
            width:auto;
          
            &:nth-child(2),&:nth-child(3){
                width:auto;
            }
            padding: 20px;
            border: 1px solid rgb(12, 12, 12);
           }
         }
         button{
            border:none;
            cursor:pointer;
            background-color: #fece51;
            flex:1;
            @include sm{
                padding:10px;
            }
            img{
                width: 24px;
                height: 24px;
            }
         }
    }
}
@import "../../responsive.scss";
.homepage{
    display: flex;
    height: 100%;
    .textcontainer{
flex:3;
.wrapper {
  padding-right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:50px;
  height: 100%;
  @include md{
padding:0;
  }
  @include sm{
   justify-content: flex-start;
  }
  .title{
    font-size: 52px;
    @include lg{
      font-size: 48px;
    }
  }
  .boxes{
    display: flex;
    justify-content: space-between;
    @include sm{
      display: none;
    }
    h1{
        font-size: 30px;
        @include lg{
          font-size: 28px;
        }
    }
    h2{
        font-size: 18px;
        font-weight: 300;
    }
  }
}
    }
    .imgcontainer{
flex:2;
background-color: #fcf5f3;
position: relative;
display: flex;
align-items: center;
@include md{
  display: none;
}
img {
    width:115%;
    position: absolute;
    right:0;
    @include lg{
      width:105%;
    }
}
    }
}